<template>
  <div class="laws-wrapper">
    <van-nav-bar
      fixed
      title="法律法规"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <van-search
      v-model="query.title"
      placeholder="请输入标题"
      @search="onSearch"
      @clear="onCancel"
    >
      <template #left-icon>
        <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
      </template>
    </van-search>
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="query.coerceDegree"
        :options="option1"
        @change="onSearch"
      />
      <van-dropdown-item
        v-model="query.type"
        :options="option2"
        @change="onSearch"
      />
    </van-dropdown-menu>
    <div class="search-total">共{{ total }}条记录</div>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      class="laws-list"
      @load="getData"
    >
      <div
        v-for="item in list"
        :key="item.id"
        class="laws-list-item"
        @click="onClickToDetail(item)"
      >
        <div class="laws-title">{{ item.title }}</div>
        <div class="laws-type">
          <span class="laws-type-first"
            ><i class="iconfont iconfont-guankongcengji"></i
            >{{ item.typeName }}</span
          >
          <span
            ><i class="iconfont iconfont-qiangzhichengdu"></i
            >{{ coerceDegreeMap[item.coerceDegree] }}</span
          >
        </div>
        <div class="laws-publish-date">
          <i class="iconfont iconfont-riqi"></i>{{ item.publishDate }}
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getLawsPage, getLawsType } from "@/api/psm";
export default {
  name: "LawView",
  data() {
    return {
      finished: false,
      coerceDegreeMap: {
        "0": "强制性",
        "1": "推荐性"
      },
      option1: [
        { text: "所有强制程度", value: "" },
        { text: "强制性", value: "0" },
        { text: "推荐性", value: "1" }
      ],
      option2: [{ text: "全部类别", value: "" }],
      query: {
        page: 1,
        size: 10,
        title: "",
        coerceDegree: "", // 强制程度
        type: "" // 类别
      },
      total: 0,
      list: [],
      loading: false
    };
  },
  created() {
    this.getSelect();
  },
  methods: {
    onClickToDetail(item) {
      this.$router.push({
        name: "laws-detail",
        params: {
          id: item.id
        }
      });
    },
    getData() {
      this.loading = true;
      getLawsPage(this.query)
        .then(res => {
          const { list, total } = res;
          if (this.query.page === 1) {
            this.list = [];
          }
          this.total = total;
          this.loading = false;
          if (list.length < this.query.size) {
            this.finished = true;
          } else {
            this.query.page++;
          }
          this.list = this.list.concat(list);
        })
        .catch(e => {
          this.finished = true;
          this.loading = false;
        });
    },
    getSelect() {
      getLawsType().then(res => {
        const types = res.map(a => {
          return {
            text: a.label,
            value: a.value
          };
        });
        this.option2 = this.option2.concat(types);
      });
    },
    onClickLeft() {
      this.$router.back();
    },
    onSearch() {
      this.query.page = 1;
      this.getData();
    },
    onCancel() {}
  }
};
</script>

<style lang="scss">
.laws-wrapper {
  padding-top: 60px;
  background-color: #f3f5fa;
  height: 100%;
  box-sizing: border-box;
  .van-dropdown-menu__bar {
    box-shadow: none;
  }
  .search-total {
    text-align: center;
    font-size: 12px;
    color: #b9bac5;
    padding-top: 6px;
    background-color: #fff;
    margin-top: 1px;
  }
  .laws-list {
    &-item {
      background-color: #fff;
      margin-bottom: 8px;
      padding: 12px 16px;
      color: #8c8f97;
      font-size: 12px;
      .laws-title {
        font-size: 16px;
        font-weight: 500;
        color: #2e2e4d;
        margin-bottom: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .iconfont {
        font-size: 14px;
        margin-right: 4px;
      }
      .laws-type {
        .laws-type-first {
          margin-right: 20px;
        }
        margin-bottom: 11px;
      }
    }
  }
}
</style>
